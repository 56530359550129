import React, { useContext } from "react";
import "./cards.css";
import Card from "../Card/card";
import { CarsContext } from "../../App";

const Cards = () => {
  const carDetails = useContext(CarsContext);

  // useEffect(() => {
  //   const Interval = setInterval(() => {
  //     setsliderState((state) => {
  //       if (state === 5) {
  //         state = 0;
  //         return state;
  //       } else {
  //         return state + 1;
  //       }
  //     });
  //   }, 2000);

  //   return () => {
  //     clearInterval(Interval);
  //   };
  // }, []);

  let CarDetails = carDetails.map((carDetail, index) => {
    return (
      <Card
        key={index}
        carId={carDetail.carid}
        carOverview={carDetail.overview}
        carImages={carDetail.images}
      />
    );
  });

  return (
    <>
      <div className="container_card">{CarDetails}</div>
    </>
  );
};

export default Cards;
