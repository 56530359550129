import React, { useEffect, useState } from "react";
import { db } from "./firebase.config";
import { collection, getDocs } from "firebase/firestore";
import "./App.css";
import Home from "./component/Home/Home";

export const CarsContext = React.createContext();
export const SliderContext = React.createContext();

const App = () => {
  const [carsData, setcarsData] = useState([]);
  const [sliderImgs, setsliderImgs] = useState([]);

  const dbCarsCollectionRef = collection(db, "cars");
  const dbSliderCollectionRef = collection(db, "slider");

  useEffect(() => {
    const getData = async () => {
      console.log("featching data from firebase");
      const carsCollection = await getDocs(dbCarsCollectionRef);
      const sliderCollection = await getDocs(dbSliderCollectionRef);
      setcarsData(carsCollection.docs.map((doc) => ({ ...doc.data() })));
      setsliderImgs(sliderCollection.docs.map((doc) => ({ ...doc.data() })));
    };
    getData();
  }, []);

  return (
    <div className="aaps">
      <CarsContext.Provider value={carsData}>
        <SliderContext.Provider value={sliderImgs}>
          <Home />
        </SliderContext.Provider>
      </CarsContext.Provider>
    </div>
  );
};

export default App;
