import React from "react";
import "./header.css";
import Logo from "../Header/image1.png";
import Play from "../Header/image.png";
import Icon from "../Header/Icon-color.png";

const Header = () => {
  return (
    <div className="container_header">
      <div className="border">
        <div className="call-icon">
          <img src={Icon} alt="" />
        </div>
        <div className="border1">
          <div>Call for enquiry</div>
          <div>+91 9999314334</div>
        </div>
      </div>
      <div className="logo_img">
        <img src={Logo} alt="" />
      </div>
      <div className="play_img">
        <a href="https://play.google.com/store/apps/details?id=com.sarobi.thecarchop&hl=en-IN">
          <img src={Play} alt="" />
        </a>
      </div>
    </div>
  );
};

export default Header;
