import React, { useState, useEffect } from "react";
import Manualitem from "./Manual_item";
// import Slider from "./Slider/Slider";
import Cardata from "./car_data";
import { useParams } from "react-router-dom";
import { db } from "../../firebase.config";
import { doc, getDoc } from "firebase/firestore";
const Page2 = () => {
  const Params = useParams();

  const carId = Params.carId;

  const [carData, setCarData] = useState([]);

  const dbCarCollectionRef = doc(db, "cars", carId);

  useEffect(() => {
    const getCarData = async () => {
      console.log("featching data from firebase");
      const carCollection = await getDoc(dbCarCollectionRef);
      setCarData(carCollection.data());
    };

    getCarData();
  }, []);

  return (
    <div className="Manual">
      <Manualitem
        carName={carData.length === 0 ? "" : carData.overview[0].value}
        carImages={carData.images}
      />
      <Cardata
        carOveriew={carData.overview}
        carFacilities={carData.facilities}
      />
    </div>
  );
};

export default Page2;
