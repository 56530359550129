import React from "react";
import Banner from "../Banner/Banner1";
import Header from "../Header/header";
import Featured from "../Banner/Featured";
import Cards from "../Cards/cards";
import Page2 from "../Page2/Page2";
import { Routes, Route } from "react-router-dom";
import Footer from "../Footer/Footer.jsx";

const home = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Banner />
              <Featured />
              <Cards />
            </>
          }
        />
        <Route path="/:carId" element={<Page2 />} />
        <Route
          path="*"
          element={
            <>
              <Banner />
              <Featured />
              <Cards />
            </>
          }
        />
      </Routes>
      <Footer/>
    </div>
  );
};

export default home;
