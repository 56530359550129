import React, { useState } from "react";
import "../Page2/Manual_item.css";
import Arrow from "../Page2/arrow.png";
// import Shareimg from "../Page2/share.png";
// import Enquire from "../Page2/question.png";
// import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Manual_item = (props) => {
  const [index, setindex] = useState(0);
  const { carName, carImages } = props;
  const imageUrl = carImages
    ? carImages.map((obj) => {
        return obj.imageUrl;
      })
    : [];
  // const Params = useParams();

  // const carId = Params.carId;
  const changeImage = (e, index) => {
    console.log(index);
    setindex(index);
  };
  return (
    <div className="container-Manual">
      <div className="manual_data">
        <div className="data">
          <HashLink to="/" title="" className="arrow">
            <img src={Arrow} alt="" />
          </HashLink>
          <div className="content">{carName}</div>
        </div>
        <div className="data1">
          <div className="share">
            {/* <button>
              <img src={Shareimg} alt="" />
              <span>Share</span>
            </button> */}
          </div>
          <div className="Enquire">
            {/* <button>
              <img src={Enquire} alt="" />
              <span>Enquire</span>
            </button> */}
          </div>
        </div>
      </div>
      <br />
      <div className="CAR_manual">
        <div>{}</div>
        <img src={imageUrl[index]} alt="Car Pic" />
      </div>
      <br />
      <div className="car_pic_slider">
        {imageUrl.map((obj, index) => {
          return (
            <img
              key={index}
              draggable="false"
              style={{
                width: "180px",
                height: "120px",
                margin: "0 5px",
                cursor: "pointer",
                userSelect: "none",
              }}
              src={obj}
              alt="Car Pic"
              onClick={(event) => {
                changeImage(event, index);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Manual_item;
