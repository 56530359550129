import React from "react";
import "./Footer.css";

import Logo from "../Header/image1.png";

function Footer() {
  return (
    <>
      <div className="footer_container">
        <div>
          <div className="logo_img">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="footer_contact">
          <div>Contact</div>
          <div className="contact_no">+91 9999314334</div>
        </div>
        <div className=" footer_service">
          <div>Services</div>
          <div>
            <ul className="serviceList">
              <li>Finance</li>
              <li>Warranty</li>
              <li>Insurance</li>
              <li>Servicing</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
