import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./card.css";

function Card(props) {
  const [sliderState, setsliderState] = useState(0);

  let interval = useRef();

  const slideImg = function () {
    interval.current = setInterval(() => {
      setsliderState((state) => {
        if (state === props.carImages.length - 1) {
          state = 0;
          return state;
        } else {
          return state + 1;
        }
      });
    }, 2000);
  };

  const resetSlideImg = function () {
    clearInterval(interval.current);
    setsliderState(0);
  };

  return (
    <Link id={`/${props.carId}`} to={`/${props.carId}`}>
      <div className="Card">
        <div className="img_card">
          <img
            onMouseOver={slideImg}
            onMouseLeave={resetSlideImg}
            src={props.carImages[sliderState].imageUrl}
            alt="carImage"
          />
          <div className="sliderdots">
            {props.carImages.map((value, index) => {
              return (
                <div
                  key={index}
                  className="sliderdot"
                  style={sliderState === index ? { background: "white" } : {}}
                ></div>
              );
            })}
          </div>
        </div>
        <div className="item_card">
          <div className="Vname">{props.carOverview[0].value}</div>
          <div className="price">{"₹" + props.carOverview[3].value}</div>
        </div>
        <div className="item_card1_wrapper">
          <div className="item_card1">
            <div className="Petrol1">{props.carOverview[1].value}</div>
            <div className="Petrol2">{props.carOverview[2].value}</div>
            <div className="Petrol3">{props.carOverview[5].value}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Card;
