import React, { useState, useEffect, useContext } from "react";
import "./featured.css";
import arrow from "./arrow.png";
import { SliderContext } from "../../App";
const Featured = ({ sliderDetails }) => {
  const [indexState, setindexState] = useState(0);
  const sliderDeatils = useContext(SliderContext);

  const slideImgLeft = () => {
    setindexState((state) => {
      if (state === 0) {
        state = sliderDeatils.length - 1;
        return state;
      } else {
        return state - 1;
      }
    });
  };
  const slideImgRight = () => {
    setindexState((state) => {
      if (state === sliderDeatils.length - 1) {
        state = 0;
        return state;
      } else {
        return state + 1;
      }
    });
  };

  return (
    <>
      <div className="container_featured0">
        <div className="head">
          <h3>Featured Deals</h3>
        </div>
        <div className="contaier_featured">
          <button className="leftArrow" onClick={slideImgLeft}>
            {sliderDeatils.length === 0 ? (
              ""
            ) : (
              <>
                <img src={arrow}></img>
              </>
            )}
          </button>
          <div className="img_feat1">
            {sliderDeatils.length === 0 ? (
              ""
            ) : (
              <>
                <img src={sliderDeatils[indexState].url} alt="img" />
              </>
            )}
          </div>
          <button className="rightArrow" onClick={slideImgRight}>
            {sliderDeatils.length === 0 ? (
              ""
            ) : (
              <>
                <img src={arrow}></img>
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Featured;
