import React, { useState } from "react";
import "../Page2/car_data.css";
import car_overview from "../Page2/caricon1.png";
import fuel_overview from "../Page2/fuel.svg";
import age_overview from "../Page2/age.svg";
import sunroof_overview from "../Page2/sunroof.svg";
import abs_overview from "../Page2/abs.svg";
import color_overview from "../Page2/colour.svg";
import seats_overview from "../Page2/searts.svg";
import insurance_overview from "../Page2/insurance.svg";
import condition_overview from "../Page2/consition.svg";
import transmissioin_overview from "../Page2/transmission.png";
import price_overview from "../Page2/price.svg";
import kilometer_overview from "../Page2/kilometer.svg";
import airbags_overview from "../Page2/airbags.svg";
import ebd_overview from "../Page2/ebd.svg";
import numberplate_overview from "../Page2/number.svg";
import owner_overview from "../Page2/owner.svg";
import tyres_overview from "../Page2/tyres.svg";

//facilities tab
import finance_overview from "../Page2/finance.svg";
// import insurance_overview from "../Page2/insurance.svg";
import exchange_overview from "../Page2/exchnage_ic.png";
import nametransfer_overview from "../Page2/name_transfer_ic.png";

var overviewarray = [
  car_overview,
  transmissioin_overview,
  fuel_overview,
  price_overview,
  age_overview,
  kilometer_overview,
  sunroof_overview,
  airbags_overview,
  abs_overview,
  ebd_overview,
  color_overview,
  numberplate_overview,
  seats_overview,
  owner_overview,
  insurance_overview,
  tyres_overview,
  condition_overview,
];

var facilities_arr = [
  finance_overview,
  insurance_overview,
  exchange_overview,
  nametransfer_overview,
];

const Cardata = (props) => {
  console.log(props);
  let { carOveriew, carFacilities } = props;

  carOveriew = carOveriew === undefined ? [] : carOveriew;
  carFacilities = carFacilities === undefined ? [] : carFacilities;

  const [overviewTab, setoverviewTab] = useState(true);
  const [facilitiesTab, setfacilitiesTab] = useState(false);

  const selectOverviewTab = () => {
    setoverviewTab(true);
    setfacilitiesTab(false);
  };

  const selectFaciltiesTab = () => {
    setoverviewTab(false);
    setfacilitiesTab(true);
  };

  return (
    <div className="container_cardata">
      <div className="container_cardata_item">
        <div className="car_details">
          <div
            style={overviewTab ? { borderBottom: "3px solid" } : {}}
            className="Overview"
            onClick={selectOverviewTab}
          >
            Overview
          </div>
          <div
            style={facilitiesTab ? { borderBottom: "3px solid" } : {}}
            className="Overview"
            onClick={selectFaciltiesTab}
          >
            Facilities
          </div>
        </div>
        <div className="details">
          <div
            style={overviewTab ? {} : { display: "none" }}
            className="overview_details"
          >
            {carOveriew.map((obj, index) => {
              console.log(obj);
              return (
                <div className="car_detail" key={index}>
                  <img src={overviewarray[index]} alt="" />
                  <div className="car_detail_val">{obj.param}</div>
                  <div>{obj.value}</div>
                </div>
              );
            })}
          </div>

          <div
            style={facilitiesTab ? {} : { display: "none" }}
            className="facilities_details"
          >
            {carFacilities.map((obj, index) => {
              return (
                <div className="car_detail" key={index}>
                  <img src={facilities_arr[index]} alt="" />
                  <div className="car_detail_val">{obj.param}</div>
                  <div>{obj.value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardata;
